import React, {useRef, useState} from 'react';
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {getMinutesFromSeconds} from "../../helpers/getTimeFromMinutes";

export default function EventDurationSettings(props) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const timeDuration = parseInt(getMinutesFromSeconds(props.timeDuration));

    return (
        <span className="ml-1" ref={ref}>
            <span className="fa fa-clock-o" onClick={props.hasErrorFunc() ? null : handleClick} data-testid="duration-settings-icon"></span>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Title as="h3">Налаштування тривалості таймів матчу</Popover.Title>
                    <Popover.Content>
                        <Form>
                            <Form.Group as={Row} controlId="formHorizontalTimeDuration">
                                <Form.Label size="sm" column sm={4}>
                                    Тривалість тайму(хв)
                                </Form.Label>
                                <Col sm={8} data-testid="halftime-duration">
                                    <Form.Control className="w-100" size="sm" type="number" placeholder="TimeDuration"
                                                  defaultValue={timeDuration} onChange={props.updateDuration} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formHorizontalTimesCount">
                                <Form.Label size="sm" column sm={4}>
                                    Кількість таймів
                                </Form.Label>
                                <Col sm={8} data-testid="halftime-count">
                                    <Form.Control className="w-100"  size="sm" type="number" placeholder="TimesCount"
                                                  defaultValue={props.timesCount} onChange={props.updatePeriodCounts} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col className="text-right">
                                    <Button variant="secondary" size="sm" type="button" className="mr-1" data-testid="cancel-button"
                                            onClick={()=>setShow(false)}>Закрити</Button>
                                    <Button variant="orange" size="sm" type="button" data-testid="save-button"
                                            onClick={()=>setShow(false)}>Змінити</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </span>
    );
}
