export function getTimeFromSeconds(totalMinutes)
{
    var sec_num = parseInt(totalMinutes, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
};
export function getMinAndSecFromSeconds(totalSeconds)
{
    var sec_num = parseInt(totalSeconds, 10); // don't forget the second param
    var minutes = Math.floor((sec_num) / 60);
    var seconds = sec_num  - (minutes * 60);

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ':' + seconds;
};

export function getMinutesFromSeconds(totalSeconds)
{
    var sec_num = parseInt(totalSeconds, 10); // don't forget the second param
    var minutes = Math.floor(sec_num/ 60);


    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    return  minutes + '′';
}

export default {getTimeFromSeconds, getMinAndSecFromSeconds, getMinutesFromSeconds}