import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import chroma from 'chroma-js';

export default class AddEventParamModal extends React.Component{

    componentDidMount() {
        this.closeModal1 = this.closeModal1.bind(this);
        this.saveModal = this.saveModal.bind(this);
        this.handlePlayerChange = this.handlePlayerChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.cleanUpState();
    }

    cleanUpState(){
        this.setState({
            personId: null,
            teamId: 0,
            statParamId: 0,
            time: undefined
        })
    }

    closeModal1(e){
        this.props.onHide();
    }
    handlePlayerChange(e) {
        this.setState({
            personId: e.value,
        })
    }
    handleTimeChange(e){
        this.setState({
            time: e.target.value,
        })
    }

    saveModal(){
        const eventParam = this.props.eventParam;
        const statParamToSave = {
            personId: this.state?.personId??eventParam?.personId,
            teamId: eventParam?.statTeamId,
            typeId: eventParam?.statParamId,
            value: this.state?.time??eventParam?.time,
            id: eventParam.id
        }
        this.cleanUpState();
        this.props.saveModal(statParamToSave);
    }
    render() {
        const {saveModal, teamMembers, eventParam, statParams, ...rest} = this.props;

        const statParamId = eventParam?.statParamId;
        const time = eventParam?.time;
        const personId = eventParam?.personId;

        const statParamName = (statParams && statParamId in statParams)?statParams[statParamId].name:'';

        const renderTooltip = (props) => (
            <Tooltip id="tooltip" {...props}>
                Функціонал подачі заявки на матч зявиться згодом...
            </Tooltip>
        );

        const defaultValue = teamMembers.find(element => element.value === personId);
        const colourStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = chroma(data?.color??'#CC0000');
                return {
                    ...styles,
                    ':before': isDisabled
                        ? {
                        content: '"\f249"',
                        font: '14px/1 FontAwesome',
                        color: data.color??color }
                        : null
                };
            },
            menu: base => ({ ...base, position: 'relative' })
        };

        return (
            <Modal
                {...rest}
                size="xl"
                backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">
                        <div className="modal-event-stat-title">{statParamName}</div>
                        <div className="modal-event-stat-time">
                            <input type="text" /* ref={this.timeInputRef}*/ defaultValue={time} onChange={this.handleTimeChange} name="eventParamTime"/>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="modal-body-subtitle">
                        <span>Хто з команди здійснив дію: </span>
                        <OverlayTrigger
                            delay={{ hide: 400 }}
                            overlay={renderTooltip}
                        ><span className="fa fa fa-id-card-o"></span></OverlayTrigger></h4>
                    <Select defaultValue={defaultValue??{}}
                            classNamePrefix='t-members'
                            options={teamMembers}
                            menuIsOpen={true}
                            onChange={this.handlePlayerChange}
                            styles={colourStyles}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button id="closeModal" variant="secondary" onClick={this.closeModal1}>Відмінити</Button>
                    <Button id="saveModal" variant="orange" onClick={this.saveModal}>Зберегти</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
