export function preparePersons(personsList, teamId=null){
    return personsList.reduce(function (result, element) {
        if (!teamId || (element.teamId && teamId === element.teamId.id)) {
            var obj = {
                value: element.personId.id, label: (element.shirtNumber ?? '') + ' ' +
                    (element.personId.surname ?? '') + ' ' +
                    (element.personId.name ?? '') + ' ' +
                    (element.personId.middlename ?? '')
            };
            if(element?.isDisabled) {
                obj.isDisabled = true;
                obj.color='#FF0000';
            }
            //result[element.personId.id] = obj;
            result.push(obj);
        }
        return result;
    }, []);
}
export default {preparePersons}