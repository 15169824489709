import React from "react";
import logo from "../../assets/logo_orange.svg";

class Loader extends React.Component{
    render() {
        return (
            <div className="App-loader" data-testid="loader-container">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Loading...
                </p>
            </div>
        )
    }
}

export default Loader;
