import React from 'react';
import ReactDOM           from 'react-dom';
import * as Sentry        from "@sentry/react";
import { Integrations }   from "@sentry/tracing";
import App                from './App';
import Helmet             from "react-helmet";
import * as serviceWorker from './serviceWorker';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import {ErrorBoundary}    from "@sentry/react";
//console.log('REACT_APP_API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT)
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn         : "https://ad313d31a34041828aa155b81485dbaa@o473431.ingest.sentry.io/5508312",
        integrations: [
            new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Helmet htmlAttributes={{lang: "uk"}}>
            <title>Turnir live event application</title>
            <meta name="description" content="Додаток для ведення онлайн протоколів. Система для ведення спортивних змагань Turnir" />
            <link rel="icon" type="image/png" href='/favicon.ico' sizes="16x16" />
        </Helmet>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>

    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
