import axios from 'axios';

export default class TournamentService {
    constructor(eventId, authToken) {
        this.eventId     = eventId;
        this.authToken   = authToken;
        this.apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
        let service      = axios.create({
            //headers: {csrf: 'token'}
        });
        //service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service     = service;
    }

    getEventDetails(callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'event');
        bodyFormData.append('data[id]', this.eventId);
        bodyFormData.append('isBuildJsonApi', true);
        bodyFormData.append('Authorization', this.authToken);

        return axios.post(this.apiEndPoint, bodyFormData)
            .then(response => {
                    if(response.data.success === false ||
                        typeof response.data.included === 'undefined' ||
                        response.data === ''){
                        callback('hasError', true);
                        return;
                    }

                    //fix problem of missing teamId after ApiJson deserialization
                    for (let i = 0; i < response.data.included.length; i++) {
                        if (response.data.included[i].type === 'team') {
                            response.data.included[i].attributes.teamId = response.data.included[i].attributes.id;
                        }
                    }
                    var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
                    return new JSONAPIDeserializer({keyForAttribute: 'camelCase'})
                        .deserialize(response.data, function (err, json) {
                            callback('eventObj', json[0])
                        });
                },
                response => {
                    console.error("Error: axios.post rejected promise");
                    callback('hasError', true);
                    return;
                }
            )
    }

    getEventMembers(callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'eventPerson');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('isBuildJsonApi', true);
        bodyFormData.append('Authorization', this.authToken);

        return axios.post(this.apiEndPoint, bodyFormData)
            .then(response => {
                    var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
                    return new JSONAPIDeserializer({keyForAttribute: 'camelCase'})
                        .deserialize(response.data, function (err, json) {
                            callback('eventMembers', json)
                        });
                }
            );
    }

    getEventScore(callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'eventScore');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('isBuildJsonApi', false);
        bodyFormData.append('Authorization', this.authToken);

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then((response) => {
                if (response.data[0])
                    callback('eventScore', response.data[0])
                //callback(response.status, response.data)
            });
    }

    getStatParams(callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'eventStatParam');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('isBuildJsonApi', false);
        bodyFormData.append('Authorization', this.authToken);

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then((response) => callback(response.status, response.data));
    }

    getStatParamTypes(callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'eventStatParamType');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('isBuildJsonApi', false);
        bodyFormData.append('Authorization', this.authToken);

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then((response) => callback(response.status, response.data));
    }

    saveStatParam(statParamObj, callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'save');
        bodyFormData.append('model', 'eventStatParam');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('Authorization', this.authToken);
        for (const property in statParamObj) {
            bodyFormData.append(`data[${property}]`, statParamObj[property] ?? '');
        }

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then(
                (response) => callback(response.status, response.data, false),
                (error) => {
                    console.log('saveStatParam error', error, statParamObj);
                    callback(error?.response?.status ?? 500, statParamObj, true)
                }
            );
    }

    saveEventScore(obj, callback) {
        var bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'save');
        bodyFormData.append('model', 'eventScore');
        bodyFormData.append('data[eventId]', this.eventId);
        bodyFormData.append('data[id]', obj.id ?? '');
        bodyFormData.append('data[teamAScore]', obj.teamAScore);
        bodyFormData.append('data[teamBScore]', obj.teamBScore);

        bodyFormData.append('Authorization', this.authToken);

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then(
                (response) => callback(response.status, response.data),
                (error) => callback(error?.response?.status ?? 500, null)
            );
    }

    removeStatParams(statParamId, removeCallback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'remove');
        bodyFormData.append('model', 'eventStatParam');
        bodyFormData.append('data[id]', statParamId);
        bodyFormData.append('Authorization', this.authToken);

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then(
                (response) => removeCallback(statParamId, response.data)
            );
    }

    // TODO - add default success handler here
    handleSuccess(response) {
        return response;
    }

    // TODO - add error handling here
    handleError = (error) => {
        console.log('TournamentService error', error);
        switch (error?.response?.status) {
            case 401:
                break;
            case 404:
                break;
            default:
                break;
        }
        return Promise.reject(error)
    }

    getTeamMembers(contestMemberId, callback) {
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'getBy');
        bodyFormData.append('model', 'personToTeam');
        bodyFormData.append('data[contestMemberId]', contestMemberId);
        bodyFormData.append('isBuildJsonApi', true);
        bodyFormData.append('Authorization', this.authToken);

        axios.post(this.apiEndPoint, bodyFormData)
            .then(response => {
                const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
                new JSONAPIDeserializer({keyForAttribute: 'camelCase'})
                    .deserialize(response.data, function (err, json) {
                        callback(json)
                    });
            });
        /*return this.service.post(this.apiEndPoint, bodyFormData)
            .then((response) => {

                const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
                new JSONAPIDeserializer({keyForAttribute: 'camelCase'})
                    .deserialize(response.data, function (err, json) {
                        console.log('jjjj', json)
                        //callback(json)
                    });
                });*/
    }

    createEventMember(memberObject, callback) {
        /*controller: Sport\Wizard\Handlers\WizardController
        method: save
        model: eventPerson
        data[eventId]: 32408
        data[personId]: 148
        data[teamId]: 83
        data[personTypeId]: 1
        data[id]:

        */
        const bodyFormData = new FormData();
        bodyFormData.append('controller', 'Sport\\Wizard\\Handlers\\WizardController');
        bodyFormData.append('method', 'save');
        bodyFormData.append('model', 'eventPerson');
        bodyFormData.append('data[id]', '');
        bodyFormData.append('Authorization', this.authToken);

        for (const property in memberObject) {
            bodyFormData.append(`data[${property}]`, memberObject[property] ?? '');
        }

        return this.service.post(this.apiEndPoint, bodyFormData)
            .then(
                (response) => callback(response.status, response.data),
                /* (error) => {
                     console.log('saveStatParam error', error, statParamObj);
                     callback(error?.response?.status??500, statParamObj, true, statParamObj.id)
                 }*/
            );

    }

}
/*
try {
    this.setState({...this.state, isFetching: true});
    const response = await axios.get(USER_SERVICE_URL);
    this.setState({users: response.data, isFetching: false});
} catch (e) {
    console.log(e);
    this.setState({...this.state, isFetching: false});
}*/
