import Container from "react-bootstrap/Container";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import StatParams from "../StatParams/StatParams";

export function OnlineEventsControl(props) {
    return (
        <Container className="online-events text-left px-0">
            <Row>
                <Col className="my-2">
                    <h2>Доступні події матчу:</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={6} data-testid="teamA-squad" className="border-right px-1 px-md-3">
                    <StatParams eventId={props.eventId}
                                teamId={props.teamAId}
                                statParams={props.statParams}
                                showModal={props.showModal}
                    />
                </Col>
                <Col xs={6} className="px-1 px-md-3">
                    <StatParams eventId={props.eventId}
                                teamId={props.teamBId}
                                statParams={props.statParams}
                                showModal={props.showModal}/>
                </Col>
            </Row>
        </Container>
    );
}