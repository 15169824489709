import React from "react";

export default function PersonName(props) {
    return(<>{
            props.person?.personId &&
            <span className="player-name font-weight-bold">{`${props.person.shirtNumber ?? ''} `}
                {props.person.personId.surname} {props.person.personId.name} {props.person.personId.middlename}
            </span>
        }</>);

}