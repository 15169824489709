import React               from 'react';
import './App.css';
import Navbar              from "react-bootstrap/Navbar";
import Container           from "react-bootstrap/Container";
import Row                 from "react-bootstrap/Row";
import Col                 from "react-bootstrap/Col";
import Loader              from "./components/Loader/Loader"
import Form                from "react-bootstrap/Form";
import OnlineControl       from "./components/OnlineControl/OnlineControl";
import Event               from "./components/Event/Event"
import {reactLocalStorage} from 'reactjs-localstorage';
import TournamentService   from "./services/TournamentService";
import _                   from "lodash";
import ErrorMessage        from "./components/ErrorMessage/ErrorMessage";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.setTime             = this.setTime.bind(this);
        this.getTime             = this.getTime.bind(this);
        this.updateDuration      = this.updateDuration.bind(this);
        this.saveToState         = this.saveToState.bind(this);
        this.eventLoadedCallback = this.eventLoadedCallback.bind(this);
        this.hasErrorFunc        = this.hasErrorFunc.bind(this);

        const urlParams = new URLSearchParams(window.location.search);
        const eventId   = urlParams.get('eventId');
        const token     = urlParams.get('token');
        const areUrlParamsEmpty = _.isEmpty(eventId) || _.isEmpty(token);
        this.service    = new TournamentService(eventId, token);

        const eventTime    = sessionStorage.getItem(eventId + "_time") ?? 0;
        const timeSettings = reactLocalStorage.getObject('timeSettings');

        this.state = {
            eventObj    : {},
            eventId     : eventId,
            time        : eventTime,
            /*appToken: 'Basic eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',*/
            appToken    : token,
            timeDuration: timeSettings.timeDuration ?? 1200,
            timesCount  : timeSettings.timesCount ?? 2,
            timeNr      : 1,
            hasError    : areUrlParamsEmpty
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Ensure getTime and timeDuration are numbers
        const prevTimeNr = Math.ceil((parseInt(prevState.time)+1) / prevState.timeDuration);
        const currentTimeNr = Math.ceil((parseInt(this.state.time)+1) / this.state.timeDuration);
        // Check if the time period has changed
        if (prevTimeNr !== currentTimeNr || this.state.timeNr!==currentTimeNr) {
            this.saveToState('timeNr', currentTimeNr);
        }
    }

    hasErrorFunc(){
        return this.state.hasError;
    }

    setTime(time) {
        this.setState(
            state => ({
                time: time
            })
        );
        sessionStorage.setItem(this.state.eventId + "_time", time);
    }

    getTime() {
        return this.state.time;
    }

    updateDuration(e) {
        if (e.target.value) {
            const newVal = e.target.value * 60;
            this.setState(
                state => ({
                    timeDuration: newVal
                })
            );
            let storage          = reactLocalStorage.getObject('timeSettings') ?? {};
            storage.timeDuration = newVal
            reactLocalStorage.setObject('timeSettings', storage);
        }
    }

    eventLoadedCallback() {
        let loader = document.querySelector('.App-loader');
        if (loader)
            loader.remove();

        //footer position update
        const client_height  = document.documentElement.clientHeight;
        const real_height    = document.documentElement.offsetHeight
        const footer_element = document.querySelector('footer');
        if (client_height >= real_height && footer_element) {
            footer_element.className += ' footer_bottom';
            const elements = document.getElementsByClassName('App');
            elements[0].setAttribute("style", "padding-bottom: 79px");
        }
    }

    componentDidMount() {
        if(this.state.hasError) {
            this.forceUpdate();
        } else {
            this.service.getEventDetails(this.saveToState);
        }
    }

    saveToState(property, obj) {
        if (typeof this.state[property] === 'object' && !Array.isArray(this.state[property])) {
            this.setState(prevState => ({
                [property]: {                   // object that we want to update
                    ...prevState[property],    // keep all other key-value pairs
                    ...obj       // update the value of specific key
                }
            }))
        } else {
            this.setState(prevState => ({
                [property]: obj
            }))
        }
    }

    render() {
        return (
            <div className="App">
                <Loader></Loader>
                <Navbar variant="turnir" sticky="top">
                    <Navbar.Brand className="">
                        <img
                            src={require('./assets/logo_white.svg')}
                            alt="Turnir live event додаток"
                        />
                    </Navbar.Brand>
                    <Form inline className="d-flex justify-content-center w-100">
                        <OnlineControl setTime={this.setTime} time={this.state.time}
                                       timeDuration={this.state.timeDuration}
                                       timesCount={this.state.timesCount}
                                       updateDuration={this.updateDuration}
                                       updatePeriodCounts={(e) => {
                                           const value = e.target.value;
                                           if (value) {
                                               this.setState(
                                                   state => ({
                                                       timesCount: value
                                                   })
                                               );
                                               let storage        = reactLocalStorage.getObject('timeSettings') ?? {};
                                               storage.timesCount = value
                                               reactLocalStorage.setObject('timeSettings', storage);
                                           }
                                       }}
                                       hasErrorFunc={this.hasErrorFunc}
                        />
                    </Form>
                </Navbar>
                {
                    !this.state.hasError && !_.isEmpty(this.state.eventObj)?
                        <Event eventId={this.state.eventId} getTime={this.getTime}
                               service={this.service}
                               eventObj={this.state.eventObj}
                               eventLoadedCallback={this.eventLoadedCallback}
                               setFouls={()=>{this.setState((prevState) => ({
                                                        fouls: {teamAFoul: 0, teamBFoul: 0}
                                                    }))}}
                               timeDuration={this.state.timeDuration}
                               timeNr={this.state.timeNr}
                        /> :
                        <ErrorMessage eventId={this.state.eventId}
                                      appToken={this.state.appToken}
                                      eventLoadedCallback={this.eventLoadedCallback}
                        />
                        /*
                        <>You have not correct configuration of the application, please start it over again or contact
                        us.</>
                        */
                }
                <footer className="w-100">
                    <Container className="px-0">
                        <Row className="justify-content-md-center">
                            <Col md="auto" className="py-2">©turnir.net.ua – {(new Date().getFullYear())}. Всі права
                                захищені.<br/>
                                При використанні даних посилання на наш ресурс обов'язкове.</Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        );
    }
}

export default App;
