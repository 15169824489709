import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import React, {useRef} from "react";
import {getMinAndSecFromSeconds} from "../../helpers/getTimeFromMinutes";
import EventDurationSettings from "../EventDurationSettings/EventDurationSettings";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

class OnlineControl extends React.Component {
  constructor(props) {
    super(props);
    this.startOnline = this.startOnline.bind(this);
    this.pauseOnline = this.pauseOnline.bind(this);

    this.state = {
      isOnline: false, showTooltip: false
    }
  }

  componentDidMount() {
    const isOnline = parseInt(sessionStorage.getItem("isOnline")) ?? 0
    if (isOnline === 1) {
      this.startOnline();
    }
  }

  startOnline() {
    let halftimeSeconds = [];
    for (let i = 1; i <= this.props.timesCount; i++) {
      halftimeSeconds.push(i * this.props.timeDuration);
    }
    const stopTimeHandler = this.pauseOnline;

    this.timer = setInterval(() => {
      let time = parseInt(this.props.time) + 1;
      if (halftimeSeconds.indexOf(time) > -1) {
        stopTimeHandler();
        //TODO: show message half time||match is over
        this.setState({showTooltip: true});
      }
      this.props.setTime(time);
    }, 1000);
    this.setState({isOnline: true});
    sessionStorage.setItem("isOnline", 1);
  }

  pauseOnline() {
    clearInterval(this.timer)
    this.setState({isOnline: false});
    sessionStorage.setItem("isOnline", 0);
  }

  render() {
    const isOnline = this.state.isOnline;

    return (<>
      <EventTime time={this.props.time} isOnline={isOnline}
                 timeDuration={this.props.timeDuration}
                 timesCount={this.props.timesCount}
                 updateDuration={this.props.updateDuration}
                 updatePeriodCounts={this.props.updatePeriodCounts}
                 hasErrorFunc={this.props.hasErrorFunc}
      ></EventTime>
      {isOnline
        ? <PauseOnlineButton onClick={this.pauseOnline}/>
        : <StartOnlineButton onClick={this.startOnline} time={this.props.time}
                             showTooltip={this.state.showTooltip}
                             hideTooltip={() => {
                               this.setState({showTooltip: false});
                             }}
                             hasErrorFunc={this.props.hasErrorFunc}
        />
      }</>);
  }
}

function StartOnlineButton(props) {
  const target = useRef(null);

  return (<>
    <Button disabled={props.hasErrorFunc()}
            variant="white"
            className="mr-sm-2 onlineBtn"
            ref={target} onClick={() => {
      props.hideTooltip();
      props.onClick()
    }}>
      <i className="fa fa-play"></i>
      {(props.time === 0) ? 'ПОЧАТИ МАТЧ' : 'ПРОДОВЖИТИ'}
    </Button>
    <Overlay target={target.current} show={props.showTooltip} placement="bottom">
      {(props) => (<Tooltip id="overlay-example" {...props} >
        Тайм завершився
      </Tooltip>)}
    </Overlay>
  </>);
}

function PauseOnlineButton(props) {
  return (<Button variant="white"
                  className="mr-sm-2 onlineBtn"
                  onClick={props.onClick}>
    <i className="fa fa-pause"></i>
    ПАУЗА
  </Button>);
}

function EventTime(props) {
  const btnClassName = props.hasErrorFunc() ? "timer btn btn-white-disabled align-middle d-inline-flex align-items-center" : "timer btn btn-white align-middle d-inline-flex align-items-center";
  return (<span data-testid="timer-element" className={btnClassName}>
                {getMinAndSecFromSeconds(props.time)}
    <EventDurationSettings timeDuration={props.timeDuration}
                           timesCount={props.timesCount}
                           updateDuration={props.updateDuration}
                           updatePeriodCounts={props.updatePeriodCounts}
                           hasErrorFunc={props.hasErrorFunc}
    ></EventDurationSettings>
    {props.isOnline && <span className="online" data-testid="online-indicator">
                    <Spinner
                      as="i"
                      animation="grow"
                      variant="danger"
                      size="sm"
                    />
                    LIVE
                </span>}
            </span>);
}

export default OnlineControl;