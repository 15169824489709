import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React from "react";
import Button from "react-bootstrap/Button";
import PersonName from "../PersonName/PersonName";
import _ from 'lodash/core';

export default class StatParamsLog extends React.Component {
    constructor(props) {
        super(props);
        this.count = 0;
        this.editStatParam = this.editStatParam.bind(this);
        this.removeStatParam = this.removeStatParam.bind(this);

    }

    preparePlayersList(list) {
        const arrResult = [];
        list.forEach(function (value) {
            if (value.personId)
                arrResult[value.personId.id] = value;
        });
        return arrResult;
    }

    editStatParam(...args) {
        this.props.showModal(...args);
    }

    removeStatParam(statParamId) {
        this.props.removeParamMethod(statParamId);
    }


    render() {
        this.count++;
        const log = this.props.statLog;
        const eventMembers = this.preparePlayersList(this.props.eventMembers);
        const statParams = this.props.statParams;
        if (_.size(statParams) === 0)
            return '';

        const rows = Object.keys(log).map((key) => {
            const hAlignmentClass = this.props.teamAId === log[key].teamId ? 'justify-content-start' : 'justify-content-end';

            /*if (!log[key].personId)
                return '';*/

            return (
                <Row key={key} className={`stat-params-log align-items-center ${hAlignmentClass}`}>
                    {
                        this.props.teamAId === log[key].teamId &&
                        <Col xs={8} md={5} className="text-right align-middle">
                            <span className="param-team-a">
                                <PersonName person={eventMembers[log[key].personId]}></PersonName>
                                <span className="stat-param"> {statParams[log[key].typeId].name}</span>
                            </span>
                        </Col>
                    }

                    <Col xs={4} md={2} className="text-nowrap text-center align-middle px-0">
                        {log[key].value}
                        <Button variant="outline-orange" size="sm ml-1"
                                onClick={() => this.editStatParam(log[key].typeId, log[key].teamId, log[key].value, log[key].personId, log[key].id)}>
                            <span className="fa fa-edit" aria-hidden="true"></span>
                        </Button>
                        <Button variant="outline-orange" size="sm ml-1"
                                onClick={() => this.removeStatParam(log[key].id)}>
                            <span className="fa fa-trash-o" aria-hidden="true"></span>
                        </Button>
                    </Col>
                    {
                        this.props.teamBId === log[key].teamId &&
                        <Col xs={8} md={5} className="text-left align-middle">
                    <span className="param-team-b">
                        <span className="stat-param">{statParams[log[key].typeId].name} </span>
                        <PersonName person={eventMembers[log[key].personId]}></PersonName>
                    </span>
                        </Col>
                    }
                </Row>
            )
        })

        const title = _.size(log) ? <h2>Хід Гри</h2> : '';

        return (
            <Container className="text-left bg-white px15">
                <Row className="">
                    <Col className="my-2">
                        {title}{/* {this.count}*/}
                    </Col>
                </Row>
                {rows}
            </Container>
        )
    }

}