import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React from "react";
import Moment from "react-moment";
import StatParamsLog from "../StatParamsLog/StatParamsLog";
import {OnlineEventsControl} from "../OnlineEventsContol/OnlineEventsControl";
import AddEventParamModal from "../AddEventParamModal/AddEventParamModal";
import objectIdAsDefaultKey from "../../helpers/objectIdAsArrayKey";
import {reactLocalStorage} from 'reactjs-localstorage';
import _ from 'lodash';
import {getMinutesFromSeconds} from "../../helpers/getTimeFromMinutes";
import Score from "../Score/Score";
import {TeamSquad} from "../TeamSquad/TeamSquad";
import {preparePersons} from "../../helpers/preparePersonsJson";
import EventLogicCalculator from "../../helpers/EventLogicCalculator";
import {DownloadProtocol} from "../../helpers/DownloadProtocol";

export default class Event extends React.Component {
  constructor(props) {
    super(props);

    this.apiService = props.service;
    this.logicCalculator = new EventLogicCalculator();
    this.eventLoadedCallback = props.eventLoadedCallback;


    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveModal = this.saveModal.bind(this);
    this.saveToState = this.saveToState.bind(this);
    this.updateStatParamsLog = this.updateStatParamsLog.bind(this);
    this.removeParam = this.removeParam.bind(this);
    this.saveEventMembers = this.saveEventMembers.bind(this);
    this.eventLogicCalculation = this.eventLogicCalculation.bind(this);

    this.storageKey = 'offlineStatLog_' + this.props.eventId;
    this.seasonSquad = {};

    this.state = {
      isApiLoaded: false,

      eventScore: {teamAScore: 0, teamBScore: 0},
      eventParamToAdd: {},
      eventObj: props.eventObj,
      eventMembers: [],
      statParams: [],
      eventStatParams: {},
      fouls: [],
      isModalShow: false,
      showTeamsSquad: false,
    };
  }

  componentDidMount() {
    const promiseEventMember = this.apiService.getEventMembers(this.saveToState);
    const promiseEventScore = this.apiService.getEventScore(this.saveToState);
    const promiseStatParam = this.apiService.getStatParamTypes((httpStatus, result) => {
      const statParamsDict = objectIdAsDefaultKey(result);
      this.saveToState('statParams', statParamsDict);
      this.logicCalculator.setParamsDictionary(statParamsDict);
    });
    const promiseEventStatParams = this.apiService.getStatParams((httpStatus, result) => {
      this.saveToState('eventStatParams', objectIdAsDefaultKey(result))

      //check local storage for stat params to save
      const notSavedStatParams = reactLocalStorage.getObject(this.storageKey);
      for (const [value] of Object.entries(notSavedStatParams)) {
        this.saveModal(notSavedStatParams[value]);
      }
      reactLocalStorage.remove(this.storageKey);
    });

    Promise.all([promiseEventMember, promiseEventStatParams, promiseEventScore, promiseStatParam]).then(values => {
      const teamAid = this.state.eventObj.teamA?.teamId;
      const teamAEventId = this.state.eventObj.teamA?.id;
      const teamBid = this.state.eventObj.teamB?.teamId;
      const teamBEventId = this.state.eventObj.teamB?.id;
      const eventMembers = this.state.eventMembers;

      if ((teamAid && eventMembers.find(e => e.teamId?.id === teamAid) === undefined) ||
        (teamBid && eventMembers.find(e => e.teamId?.id === teamBid) === undefined)) {
        this.apiService.getTeamMembers(teamAEventId, (result) => {
          const teamSeasonSquad = preparePersons(result);
          this.saveToState('seasonSquad', {teamA: teamSeasonSquad});
        });
        this.apiService.getTeamMembers(teamBEventId, (result) => {
          const teamSeasonSquad = preparePersons(result);
          this.saveToState('seasonSquad', {teamB: teamSeasonSquad});
        });
        this.saveToState('showTeamsSquad', true);
      }

      this.eventLogicCalculation(this.state.eventStatParams);
      this.saveToState('isApiLoaded', true)
      this.eventLoadedCallback();
    });
  }


  openModal(statParamId, teamId, time = null, personId = null, id = null) {
    const timeInSeconds = time ?? getMinutesFromSeconds(this.props.getTime())

    this.setState({
      eventParamToAdd: {
        statTeamId: teamId,
        statParamId: statParamId,
        time: timeInSeconds,
        personId: personId,
        id: id
      },
      isModalShow: true,
    });
  }

  closeModal() {
    this.setState({isModalShow: false});
  }

  saveModal(statParam) {
    const [transformedStatParam, statParamsToRemove] = this.logicCalculator.getTransformedStatParam(statParam, this.state.eventStatParams)
    this.apiService.saveStatParam(transformedStatParam, this.updateStatParamsLog);
    _.forEach(statParamsToRemove, function (value) {
      this.removeParam(value.id);
    }.bind(this));
    this.closeModal();
  }

  saveEventMembers(teamAMembers, teamBMembers) {
    const teamASquadToSave = this.getPlayersToSave(teamAMembers, this.state.eventMembers);
    const teamBSquadToSave = this.getPlayersToSave(teamBMembers, this.state.eventMembers);

    const promisesArray = _.concat(teamASquadToSave, teamBSquadToSave).map(
      teamSquad => this.apiService.createEventMember(teamSquad, (status, response) => {
      })
    );

    Promise.all(promisesArray).then(values => {
      this.apiService.getEventMembers(this.saveToState);
      this.saveToState('showTeamsSquad', false);
    })
  }

  /** helpers*/
  getPlayersToSave(teamMembers, savedMembers) {
    return teamMembers.filter(e => !savedMembers.find(a => a.personId.id === e.personId));
  }

  updateStatParamsLog(statusCode, responseBody, offline = false) {
    let statParam = {};
    statParam = this.state.eventStatParams;
    if (!offline) {
      statParam[responseBody.id] = responseBody;
    } else {
      const hash = require('object-hash');
      const encodedString = hash(responseBody);
      let value = reactLocalStorage.getObject(this.storageKey);
      value[encodedString] = responseBody;
      statParam[encodedString] = responseBody;
      reactLocalStorage.setObject(this.storageKey, value);
    }
    this.setState(previousState => ({
      eventStatParams: statParam
    }), () => this.eventLogicCalculation(statParam));
  }

  removeParam(statParamId) {
    this.apiService.removeStatParams(statParamId, (statParamId) => {
      this.setState(state => ({
        eventStatParams: _.omit(state.eventStatParams, statParamId)
      }), () => this.eventLogicCalculation(this.state.eventStatParams));
    });
  }

  eventLogicCalculation(statParam) {
    const [newScore, blockedPersons, fouls] = this.logicCalculator.calculate(statParam, this.state.statParams, this.state.eventObj.teamA.teamId, this.props.timeDuration, this.props.timeNr)

    if (newScore.teamAScore !== this.state.eventScore.teamAScore ||
      newScore.teamBScore !== this.state.eventScore.teamBScore) {
      const scoreObject = Object.assign(this.state.eventScore, newScore);
      this.apiService.saveEventScore(scoreObject, (httpStatus, result) => this.saveToState('eventScore', scoreObject));
    }

    if (fouls.foulA !== this.state.fouls[this.props.timeNr]?.teamAFoul ||
      fouls.foulB !== this.state.fouls[this.props.timeNr]?.teamBFoul) {

      const foulObject = { ...this.state.fouls };
      foulObject[this.props.timeNr] = {teamAFoul: fouls.foulA, teamBFoul: fouls.foulB}

      this.saveToState('fouls', foulObject);
    }

    if (blockedPersons.length > 0) {
      let eventMembers = this.state.eventMembers.slice();
      eventMembers = eventMembers.map((v) => {
        return blockedPersons.find(e => e.personId === v.personId.id)
          ? {...v, ...{isDisabled: true}}
          : {...v, ...{isDisabled: false}}
      })
      this.saveToState('eventMembers', eventMembers);
    }
  }
  saveToState(property, obj, callback) {
        if (typeof this.state[property] === 'object' && !Array.isArray(this.state[property])) {
            this.setState(prevState => ({
                [property]: {                   // object that we want to update
                    ...prevState[property],    // keep all other key-value pairs
                    ...obj       // update the value of specific key
                }
            }), callback)
        } else {
            this.setState(prevState => ({
                [property]: obj
            }), callback)
        }
    }

  componentDidUpdate(prevProps) {
    // Check if the time period has changed
    if (prevProps.timeNr !== this.props.timeNr) {
      this.eventLogicCalculation(this.state.eventStatParams);
    }
  }


  render() {
    if (!this.state.isApiLoaded)
      return null;
    const availableStatParams = this.state.statParams;
    const personalStatParamLog = _.filter(this.state.eventStatParams, function (item) {
      return parseInt(availableStatParams[item.typeId]?.personRelatedParam) === 1;
    });
    return (
      <div className="position-relative">
        <Container className="fullscreen-container px-0 container-xl">
          <Row className="place-time">
            <Col>
                            <span data-testid="event-date">
                                {this.state.eventObj.date &&
                                  <Moment format="DD.MM.YYYY HH:mm">
                                    {this.state.eventObj.date}
                                  </Moment>
                                }
                            </span>
              <span>Місце проведення:
                                <strong data-testid="event-place">
                                    {this.state.eventObj.place ? ' ' + this.state.eventObj.place : ' Не внесено'}
                                </strong>
                            </span>
            </Col>
          </Row>
          <Row className="team-names">
            <Col xs={6} data-testid="teamA-name">
              <h3>
                {this.state.eventObj?.teamA?.name}
              </h3>
            </Col>
            <Col xs={6} data-testid="teamB-name">
              <h3>
                {this.state.eventObj?.teamB?.name}
              </h3>
            </Col>
          </Row>
          <Row className="team-scores">
            <Col xs={3} md={2} className="fouls d-flex justify-content-end flex-column text-center">фоли:<div className="text-center">{this.state.fouls[this.props.timeNr]?.teamAFoul}</div></Col>
            <Col xs={6} md={8} className="center">
              <div className="display-2"><Score teamAScore={this.state.eventScore.teamAScore}
                                                teamBScore={this.state.eventScore.teamBScore}/></div>
            </Col>
            <Col xs={3} md={2} className="fouls d-flex justify-content-end flex-column text-center">фоли:<div className="text-center">{this.state.fouls[this.props.timeNr]?.teamBFoul}</div></Col>
          </Row>
          {
            !this.state.showTeamsSquad &&
            <Row className="teams-and-score">
              <Col xs={12}>
                <DownloadProtocol eventId={this.props.eventId}/>
              </Col>
            </Row>
          }
        </Container>
        {
          !this.state.showTeamsSquad ?
            <>
              <OnlineEventsControl eventId={this.props.eventId}
                                   teamAId={this.state.eventObj?.teamA?.teamId ?? 0}
                                   teamBId={this.state.eventObj?.teamB?.teamId ?? 0}
                                   statParams={availableStatParams}
                                   showModal={this.openModal}
              />
              <AddEventParamModal show={this.state.isModalShow}
                                  onHide={this.closeModal}
                                  saveModal={this.saveModal}
                                  eventParam={this.state.eventParamToAdd}
                                  statParams={availableStatParams}
                                  teamMembers={preparePersons(this.state.eventMembers, this.state.eventParamToAdd?.statTeamId)}
              />
              <StatParamsLog statLog={personalStatParamLog}
                             teamAId={this.state.eventObj?.teamA?.teamId ?? 0}
                             teamBId={this.state.eventObj?.teamB?.teamId ?? 0}
                             eventMembers={this.state.eventMembers}
                             statParams={availableStatParams}
                             showModal={this.openModal}
                             removeParamMethod={this.removeParam}
              />
            </> :
            <TeamSquad eventId={this.props.eventId}
                       appToken={this.props.appToken}
                       teamAId={this.state.eventObj.teamA?.teamId}
                       teamASquad={this.state.seasonSquad?.teamA}
                       teamAEventSquad={preparePersons(this.state.eventMembers, this.state.eventObj.teamA?.teamId)}
                       teamBId={this.state.eventObj.teamB?.teamId}
                       teamBSquad={this.state.seasonSquad?.teamB}
                       teamBEventSquad={preparePersons(this.state.eventMembers, this.state.eventObj.teamB?.teamId)}
                       saveTeamSquad={this.saveEventMembers}
            />
        }
      </div>
    );
  }
}