import _ from 'lodash';

export default function objectIdAsDefaultKey(array){
    const arrResult = {};
    _.forEach(array, function(value) {
        if(value.id)
            arrResult[value.id.toString()] = value;
    });

    return arrResult;
}