import React  from "react";
import Button from "react-bootstrap/Button";

export function DownloadProtocol(props) {
    const url = `${process.env.REACT_APP_NEW_WIZARD}t:0/event:${props.eventId}/download_protocol`;
    return (
        <Button variant="white" className="px-1 px-md-2 mb-3"
            onClick={()=>{
                window.open(url, '_blank', 'noreferrer');
            }}
        >
            Завантажити протокол
        </Button>
    );
}
