import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React, {useState} from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";

export function TeamSquad(props){
    const [teamASquad, setTeamASquad] = useState(props.teamAEventSquad??[]);
    const [teamBSquad, setTeamBSquad] = useState(props.teamBEventSquad??[]);
/*    // Подібно до componentDidMount та componentDidUpdate:
    useEffect(() => {
        console.log('useEffect', teamASquad, teamBSquad);
    });*/

    const handleSubmit = (evt) => {
        //console.log('submit', teamASquad, teamBSquad);
        evt.preventDefault();
        props.saveTeamSquad(
            teamASquad.map(obj=>{return {personId:obj.value, teamId:props.teamAId, eventId:props.eventId, personTypeId:1 }}),
            teamBSquad.map(obj=>{return {personId:obj.value, teamId:props.teamBId, eventId:props.eventId, personTypeId:1 }})
        );
    }

    return(
        <form onSubmit={handleSubmit}>
        <Container className="text-left">
            <Row>
                <Col className="my-2 form-group">
                    <h2>Внесіть склади команд, щоб мати змогу розпочати трансляцію:</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={6} data-testid="teamA-squad" className="border-right form-group">
                    <Select
                        isMulti
                        name="teamA-squad"
                        options={props.teamASquad}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v)=>setTeamASquad(v??[])}
                        defaultValue={props.teamAEventSquad}
                    />
                </Col>
                <Col xs={6} data-testid="teamB-squad" className="form-group">
                    <Select
                        isMulti
                        name="teamB-squad"
                        options={props.teamBSquad}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(v)=>setTeamBSquad(v??[])}
                        defaultValue={props.teamBEventSquad}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="form-group text-right">
                    <Button variant="orange" type="submit" disabled={teamASquad.length>0&&teamBSquad.length>0?false:true}>Зберегти</Button>
                </Col>
            </Row>
        </Container>
        </form>
    )
}
