import React from 'react';
import _     from "lodash";


function ErrorMessage(props){
    // remove loader
    props.eventLoadedCallback();
    // show a message
    const parentSiteUntrimmed = new URL(process.env.REACT_APP_API_ENDPOINT);
    let parentSite = parentSiteUntrimmed.protocol + "//" + parentSiteUntrimmed.hostname;
    let messageText;
    if(!_.isEmpty(props.eventId)) {
        parentSite = parentSite.concat("/ua/game/",props.eventId);
        messageText = "Помилка авторизації.";
    } else {
        messageText = "Не вдалося ідентифікувати гру для початку онлайну.";
    }
    return (
        <div>
            <p> </p>
            <div><h4>Неможливо розпочати онлайн трансляцію!</h4></div>
            <div>{messageText}<br/><br/></div>
            <div>Перейдіть на сторінку відповідної гри та натисніть посилання "Розпочати онлайн трансляцію".<br/><br/>
                Повернутися на сайт:  <a href={parentSite}>{parentSite}</a></div>
        </div>
    );
}

export default ErrorMessage;